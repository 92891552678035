import React from "react";

import { useTranslation, Trans } from "react-i18next";

import Fade from 'react-reveal/Fade'

import h1 from '../../img/Accounts/how1.png'
import h2 from '../../img/Accounts/how2.png'
import h3 from '../../img/Accounts/how3.png'
import h4 from '../../img/Accounts/how4.png'
import h5 from '../../img/Accounts/how5.png'
import h6 from '../../img/Accounts/how6.png'

function HowCan() {
    
    const { t } = useTranslation();

    return (
        <div className="relative overflow-hidden mt-[80px] xl:mt-[160px]">
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto">
              <p className="text-center mx-auto manrope text-[20px] xl:text-[40px] font-[500] leading-[1.2]"><Trans>{t('accounts.34')}</Trans></p>
              <div className="flex justify-between flex-col xl:flex-row mt-[20px] xl:mt-[60px] gap-[20px] xl:gap-0">
                <div className="xl:w-[413px] h-[268px] rounded-[10px] bg-[#EAEAE5] p-[20px]">
                    <div className="flex justify-between items-center">
                        <p className="text-[#FF5131] manrope text-[16px] xl:text-[20px] font-[600]">Mastercard</p>
                        <div className="w-[127px] h-[67px] rounded-[10px] bg-[#F4F4EF] flex items-center justify-center">
                            <img src={h1} alt="" />
                        </div>
                    </div>
                    <p className="text-[14px] xl:text-[16px] manrope opacity-[0.7] font-[600] mt-[20px]">{t('accounts.35')}</p>
                    <p className="text-[14px] xl:text-[16px] manrope opacity-[0.7] font-[400] mt-[5px]">{t('accounts.36')}</p>
                    <p className="text-[14px] xl:text-[16px] manrope opacity-[0.7] font-[400] mt-[5px] spanhow"><Trans>{t('accounts.37')}</Trans></p>
                    <p className="text-[14px] xl:text-[16px] manrope opacity-[0.7] font-[400] mt-[5px] spanhow"><Trans>{t('accounts.38')}</Trans></p>
                </div>
                <div className="xl:w-[413px] h-[268px] rounded-[10px] bg-[#EAEAE5] p-[20px]">
                    <div className="flex justify-between items-center">
                        <p className="text-[#FF5131] manrope text-[16px] xl:text-[20px] font-[600]">VISA</p>
                        <div className="w-[127px] h-[67px] rounded-[10px] bg-[#F4F4EF] flex items-center justify-center">
                            <img src={h2} alt="" />
                        </div>
                    </div>
                    <p className="text-[14px] xl:text-[16px] manrope opacity-[0.7] font-[600] mt-[20px]">{t('accounts.39')}</p>
                    <p className="text-[14px] xl:text-[16px] manrope opacity-[0.7] font-[400] mt-[5px]">{t('accounts.40')}</p>
                    <p className="text-[14px] xl:text-[16px] manrope opacity-[0.7] font-[400] mt-[5px] spanhow"><Trans>{t('accounts.41')}</Trans></p>
                    <p className="text-[14px] xl:text-[16px] manrope opacity-[0.7] font-[400] mt-[5px] spanhow"><Trans>{t('accounts.42')}</Trans></p>
                </div>
                <div className="xl:w-[413px] h-[268px] rounded-[10px] bg-[#EAEAE5] p-[20px]">
                    <div className="flex justify-between items-center">
                        <p className="text-[#FF5131] manrope text-[16px] xl:text-[20px] font-[600]">Skrill</p>
                        <div className="w-[127px] h-[67px] rounded-[10px] bg-[#F4F4EF] flex items-center justify-center">
                            <img src={h3} alt="" />
                        </div>
                    </div>
                    <p className="text-[14px] xl:text-[16px] manrope opacity-[0.7] font-[600] mt-[20px]">{t('accounts.43')}</p>
                    <p className="text-[14px] xl:text-[16px] manrope opacity-[0.7] font-[400] mt-[5px]">{t('accounts.44')}</p>
                    <p className="text-[14px] xl:text-[16px] manrope opacity-[0.7] font-[400] mt-[5px] spanhow"><Trans>{t('accounts.45')}</Trans></p>
                    <p className="text-[14px] xl:text-[16px] manrope opacity-[0.7] font-[400] mt-[5px] spanhow"><Trans>{t('accounts.46')}</Trans></p>
                </div>
              </div>
              <div className="flex justify-between flex-col xl:flex-row mt-[20px] gap-[20px] xl:gap-0">
              <div className="xl:w-[413px] h-[268px] rounded-[10px] bg-[#EAEAE5] p-[20px]">
                    <div className="flex justify-between items-center">
                        <p className="text-[#FF5131] manrope text-[16px] xl:text-[20px] font-[600]">Neteller</p>
                        <div className="w-[127px] h-[67px] rounded-[10px] bg-[#F4F4EF] flex items-center justify-center">
                            <img src={h4} alt="" />
                        </div>
                    </div>
                    <p className="text-[14px] xl:text-[16px] manrope opacity-[0.7] font-[600] mt-[20px]">{t('accounts.47')}</p>
                    <p className="text-[14px] xl:text-[16px] manrope opacity-[0.7] font-[400] mt-[5px]">{t('accounts.48')}</p>
                    <p className="text-[14px] xl:text-[16px] manrope opacity-[0.7] font-[400] mt-[5px] spanhow"><Trans>{t('accounts.49')}</Trans></p>
                    <p className="text-[14px] xl:text-[16px] manrope opacity-[0.7] font-[400] mt-[5px] spanhow"><Trans>{t('accounts.50')}</Trans></p>
                </div>
                <div className="xl:w-[413px] h-[268px] rounded-[10px] bg-[#EAEAE5] p-[20px]">
                    <div className="flex justify-between items-center">
                        <p className="text-[#FF5131] manrope text-[16px] xl:text-[20px] font-[600]">Bank/Wire Transfer</p>
                        <div className="w-[127px] h-[67px] rounded-[10px] bg-[#F4F4EF] flex items-center justify-center">
                            <img src={h5} alt="" />
                        </div>
                    </div>
                    <p className="text-[14px] xl:text-[16px] manrope opacity-[0.7] font-[600] mt-[20px]">{t('accounts.51')}</p>
                    <p className="text-[14px] xl:text-[16px] manrope opacity-[0.7] font-[400] mt-[5px]">{t('accounts.52')}</p>
                    <p className="text-[14px] xl:text-[16px] manrope opacity-[0.7] font-[400] mt-[5px] spanhow"><Trans>{t('accounts.53')}</Trans></p>
                    <p className="text-[14px] xl:text-[16px] manrope opacity-[0.7] font-[400] mt-[5px] spanhow"><Trans>{t('accounts.54')}</Trans></p>
                </div>
                <div className="xl:w-[413px] h-[268px] rounded-[10px] bg-[#EAEAE5] p-[20px]">
                    <div className="flex justify-between items-center">
                        <p className="text-[#FF5131] manrope text-[16px] xl:text-[20px] font-[600]">Fasapay</p>
                        <div className="w-[127px] h-[67px] rounded-[10px] bg-[#F4F4EF] flex items-center justify-center">
                            <img src={h6} alt="" />
                        </div>
                    </div>
                    <p className="text-[14px] xl:text-[16px] manrope opacity-[0.7] font-[600] mt-[20px]">{t('accounts.55')}</p>
                    <p className="text-[14px] xl:text-[16px] manrope opacity-[0.7] font-[400] mt-[5px]">{t('accounts.56')}</p>
                    <p className="text-[14px] xl:text-[16px] manrope opacity-[0.7] font-[400] mt-[5px] spanhow"><Trans>{t('accounts.57')}</Trans></p>
                    <p className="text-[14px] xl:text-[16px] manrope opacity-[0.7] font-[400] mt-[5px] spanhow"><Trans>{t('accounts.58')}</Trans></p>
                </div>
              </div>
            </div>
        </div>
    );
}

export default HowCan;
