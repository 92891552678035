import React, { useState } from "react";

import { useTranslation, Trans } from "react-i18next";

import Fade from 'react-reveal/Fade'

import main from '../../img/AboutUs/main.png'

const data = [
    {
        title: "aboutus.3",
        text: "aboutus.5",
    },
    {
        title: "aboutus.4",
        text: "aboutus.6",
    },
]

function Main() {
    
    const { t } = useTranslation();

    const [active, setActive] = useState(0);

    return (
        <>
            <div className="bg-[#EAEAE5] relative overflow-hidden pt-[80px] xl:pt-[120px]">
                <div className="max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col xl:flex-row justify-between">
                    <div>
                        <p className="text-[32px] xl:text-[64px] manrope text-[#FF5131] font-[600] leading-[1.2]"><Trans>{t('aboutus.1')}</Trans></p>
                        <p className="mt-[20px] xl:mt-[40px] max-w-[517px] manrope opacity-[0.7] text-[14px] xl:text-[16px]">{t('aboutus.2')}</p>
                    </div>
                    <div>
                        <Fade right>
                            <img src={main} alt="" />
                        </Fade>
                    </div>
                </div>  
            </div>
            <div className="relative overflow-hidden pt-[80px] xl:pt-[160px]">
                <div className="max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col xl:flex-row justify-between">
                    <div className="flex flex-col gap-[22px]">
                        {data.map((dat, index) => (                     
                            <div onClick={() => setActive(index)} key={index} className={`flex items-center justify-center transition-all cursor-pointer h-[58px] xl:w-[600px] rounded-[10px] ${active === index ? 'bg-[#FF5131]' : 'bg-[#F4F4EF] border border-[#00000033]'}`}>
                                <p className={`${active === index ? 'text-white' : 'text-black'} transition-all manrope text-[16px] xl:text-[24px] font-[500]`}>{t(dat.title)}</p>
                            </div>
                        ))}
                    </div>
                    <div className="mt-[20px] xl:mt-0">
                        <p className="max-w-[497px] manrope text-[14px] xl:text-[16px] opacity-[0.7]">{t(data[active].text)}</p>
                    </div>
                </div>       
            </div>
        </>
    );
}

export default Main;
