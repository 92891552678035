import React from "react";

import { useTranslation, Trans } from "react-i18next";

import Fade from 'react-reveal/Fade'

function MakeInvesting() {
    
    const { t } = useTranslation();

    return (
        <div className="overflow-hidden relative max-w-[1280px] mx-[20px] xl:mx-auto mt-[80px] xl:mt-[160px]">
            <div className="xl:h-[548px] w-full rounded-[10px] bg-[#EAEAE5] p-[20px] xl:p-[60px]">
                <p className="manrope text-[28px] xl:text-[48px] leading-[1.2]">{t('main.31')}</p>
                <p className="manrope text-[28px] xl:text-[64px] leading-[1.2] text-[#FF5131] font-[500]">{t('main.32')}</p>
                <div className="gap-y-[10px] flex flex-col xl:flex-row justify-between xl:items-end items-center mt-[20px] xl:mt-0">
                    <Fade bottom delay={300}><div className="w-full h-[99px] xl:w-[204px] xl:h-[148px] bg-[#FF51311A] border-t-[#FF5131] border-t-[5px] flex flex-col items-center justify-center">
                        <p className="roboto text-[32px] xl:text-[48px] text-[#FF5131]">50%</p>
                        <p className="roboto text-[#000000B2] text-[16px] xl:text-[20px] leading-[1.2] text-center"><Trans>{t('main.33')}</Trans></p>
                    </div></Fade>
                    <Fade bottom delay={500}><div className="w-full h-[99px] xl:w-[204px] xl:h-[184px] bg-[#FF51311A] border-t-[#FF5131] border-t-[5px] flex flex-col items-center justify-center">
                        <p className="roboto text-[32px] xl:text-[48px] text-[#FF5131]">230</p>
                        <p className="roboto text-[#000000B2] text-[16px] xl:text-[20px] leading-[1.2] text-center"><Trans>{t('main.34')}</Trans></p>
                    </div></Fade>
                    <Fade bottom delay={700}><div className="w-full h-[99px] xl:w-[204px] xl:h-[231px] bg-[#FF51311A] border-t-[#FF5131] border-t-[5px] flex flex-col items-center justify-center">
                        <p className="roboto text-[32px] xl:text-[48px] text-[#FF5131]">30</p>
                        <p className="roboto text-[#000000B2] text-[16px] xl:text-[20px] leading-[1.2] text-center"><Trans>{t('main.35')}</Trans></p>
                    </div></Fade>
                    <Fade bottom delay={900}><div className="w-full h-[99px] xl:w-[204px] xl:h-[165px] bg-[#FF51311A] border-t-[#FF5131] border-t-[5px] flex flex-col items-center justify-center">
                        <p className="roboto text-[32px] xl:text-[48px] text-[#FF5131]">24/7</p>
                        <p className="roboto text-[#000000B2] text-[16px] xl:text-[20px] leading-[1.2] text-center"><Trans>{t('main.36')}</Trans></p>
                    </div></Fade>
                    <Fade bottom delay={1100}><div className="w-full h-[99px] xl:w-[204px] xl:h-[363px] bg-[#FF51311A] border-t-[#FF5131] border-t-[5px] flex flex-col items-center justify-center">
                        <p className="roboto text-[32px] xl:text-[48px] text-[#FF5131]">1:500</p>
                        <p className="roboto text-[#000000B2] text-[16px] xl:text-[20px] leading-[1.2] text-center"><Trans>{t('main.37')}</Trans></p>
                    </div></Fade>
                </div>
            </div>
        </div>
    );
}

export default MakeInvesting;
