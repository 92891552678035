import React from "react";

import { useTranslation, Trans } from "react-i18next";

import Fade from 'react-reveal/Fade'

import main from '../../img/Accounts/main.png'

import mainp from '../../img/Accounts/main_phone.png'

function Main() {
    
    const { t } = useTranslation();

    return (
        <div className="bg-[#EAEAE5] relative overflow-hidden">
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto">
                <div className="mt-[50px] xl:mt-[160px] xl:pb-[200px]">
                    <p className="manrope text-[24px] xl:text-[38px] font-[600] leading-[1.2] uppercase text-[#FF5131]">{t('accounts.1')}</p>
                    <p className="manrope text-[32px] xl:text-[44px] font-[600] leading-[1.2] uppercase text-[#FF5131]">{t('accounts.2')}</p>
                    <p className="max-w-[517px] text-[16px] xl:text-[20px] font-[500] leading-[1.4] opacity-[0.7] manrope mt-[20px] xl:mt-[40px]">{t('accounts.3')}</p>
                    <p className="max-w-[517px] text-[16px] xl:text-[20px] font-[500] leading-[1.4] opacity-[0.7] manrope mt-[20px] xl:mt-[40px]">{t('accounts.4')}</p>
                </div>
                <Fade right>
                    <img src={main} alt="" className="hidden xl:block absolute right-0 top-0"/>
                </Fade>
            </div>
            <Fade bottom>
                <img src={mainp} alt="" className="xl:hidden block mt-[20px] "/>
            </Fade>
        </div>
    );
}

export default Main;
