import React from "react";

import { useTranslation, Trans } from "react-i18next";

import Fade from 'react-reveal/Fade'

import main from '../../img/Offers/main.png'
import main_phone from '../../img/Offers/main_phone.png'

function Main() {
    
    const { t } = useTranslation();

    return (
        <div className="bg-[#000000] overflow-hidden relative h-[760px]">
            <div className="mx-[20px] max-w-[1280px] xl:mx-auto relative z-50">
                <p className="font-[600] uppercase text-[#FF5131] manrope text-[32px] xl:text-[64px] leading-[1.2] mt-[80px] z-50 relative"><Trans>{t('offers.1')}</Trans></p>
                <p className="roboto text-[20px] xl:text-[40px] leading-[1.4] text-white mt-[20px]"><Trans>{t('offers.2')}</Trans></p>
                <p className="text-[#FFFFFFB2] mt-[40px] max-w-[517px] manrope text-[14px] xl:text-[16px] leading-[1.4]">{t('offers.3')}</p>
                <p className="text-[#FFFFFFB2] mt-[20px] max-w-[517px] manrope text-[14px] xl:text-[16px] leading-[1.4]">{t('offers.4')}</p>
                <p className="manrope text-[16px] xl:text-[20px] leading-[1.4] text-white mt-[40px] font-[500] max-w-[517px]">{t('offers.5')}</p>
            </div>
            <Fade right>
                <img src={main} alt="" className="xl:absolute right-0 bottom-0 xl:top-0 hidden xl:block"/>
            </Fade>
            <Fade bottom>
                <img src={main_phone} alt="" className="absolute w-full bottom-0 xl:hidden block"/>
            </Fade>
            
        </div>
    );
}

export default Main;
