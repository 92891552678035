import React, { useState } from "react";

import { useTranslation, Trans } from "react-i18next";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css';

import sw1 from '../../img/AboutUs/sw1.png'
import sw2 from '../../img/AboutUs/sw2.png'
import sw3 from '../../img/AboutUs/sw3.png'
import sw4 from '../../img/AboutUs/sw4.png'

import svgsw1 from '../../img/AboutUs/svgsw1.png'
import svgsw2 from '../../img/AboutUs/svgsw2.png'
import svgsw3 from '../../img/AboutUs/svgsw3.png'
import svgsw4 from '../../img/AboutUs/svgsw4.png'

const productsData = [
    { 
        title: 'aboutus.8',
        text: 'aboutus.9',
        img: sw1,
        svg: svgsw1,
    },
    { 
        title: 'aboutus.10',
        text: 'aboutus.11',
        img: sw2,
        svg: svgsw2,
    },
    { 
        title: 'aboutus.12',
        text: 'aboutus.13',
        img: sw3,
        svg: svgsw3,
    },
    { 
        title: 'aboutus.14',
        text: 'aboutus.15',
        img: sw4,
        svg: svgsw4,
    },
]

function OurValues() {
    
    const { t } = useTranslation();

    const [swiper, setSwiper] = useState({});

    return (
        <>
            <div className="relative overflow-hidden pt-[80px] xl:pt-[160px]">
                <div className="max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col">
                    <p className="manrope font-[500] text-[20px] xl:text-[40px]">{t('aboutus.7')}</p>
                    <Swiper
                        modules={[Navigation, Pagination, Autoplay]}
                        slidesPerView={1}
                        loop={true}
                        onInit={(e) => {
                            setSwiper(e);
                        }}
                        className="mt-[40px] w-full"
                    >
                        {productsData.map((product, index) => (
                            <SwiperSlide key={index}>
                                <div className="flex flex-col xl:flex-row justify-between">
                                    <div className="relative">
                                        <img src={product.img} alt=""/>
                                        <div className="w-[40px] h-[40px] xl:w-[83px] xl:h-[83px] rounded-[10px] bg-[#FF5131] absolute inset-[20px] flex items-center justify-center">
                                            <img src={product.svg} alt="" className="scale-50 xl:scale-100"/>
                                        </div>
                                    </div>
                                    <div className="mt-[20px] xl:mt-0">
                                        <p className="manrope text-[16px] xl:text-[24px] font-[600] leading-[1.2] text-[#FF5131]">{t(product.title)}</p>
                                        <p className="max-w-[497px] manrope text-[14px] xl:text-[16px] opacity-[0.7] font-[500] mt-[12px]">{t(product.text)}</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <div className='flex xl:mt-[40px] gap-[10px] ml-auto mr-[20px] xl:mr-0'>
                        <div onClick={() => swiper.slidePrev()} className="cursor-pointer">
                            <svg xmlns="http://www.w3.org/2000/svg" width="84" height="40" viewBox="0 0 84 40" fill="none">
                                <rect width="84" height="40" rx="20" fill="#FF5131"/>
                                <path d="M26.2929 19.2929C25.9024 19.6834 25.9024 20.3166 26.2929 20.7071L32.6569 27.0711C33.0474 27.4616 33.6805 27.4616 34.0711 27.0711C34.4616 26.6805 34.4616 26.0474 34.0711 25.6569L28.4142 20L34.0711 14.3431C34.4616 13.9526 34.4616 13.3195 34.0711 12.9289C33.6805 12.5384 33.0474 12.5384 32.6569 12.9289L26.2929 19.2929ZM57 19L27 19V21L57 21V19Z" fill="white"/>
                            </svg>
                        </div>
                        <div onClick={() => swiper.slideNext()} className="cursor-pointer">
                            <svg xmlns="http://www.w3.org/2000/svg" width="84" height="40" viewBox="0 0 84 40" fill="none">
                                <rect width="84" height="40" rx="20" fill="#FF5131"/>
                                <path d="M57.7071 20.7071C58.0976 20.3166 58.0976 19.6834 57.7071 19.2929L51.3431 12.9289C50.9526 12.5384 50.3195 12.5384 49.9289 12.9289C49.5384 13.3195 49.5384 13.9526 49.9289 14.3431L55.5858 20L49.9289 25.6569C49.5384 26.0474 49.5384 26.6805 49.9289 27.0711C50.3195 27.4616 50.9526 27.4616 51.3431 27.0711L57.7071 20.7071ZM27 21L57 21L57 19L27 19L27 21Z" fill="white"/>
                            </svg>
                        </div>
                    </div>
                </div>  
            </div>
           
        </>
    );
}

export default OurValues;
