import React, { useEffect } from "react";

import Main from '../components/Offers/Main'
import TakeAdvantages from "../components/Offers/TakeAdvantages";
import OpenAccounts from "../components/Offers/OpenAccounts";
import Howitworks from "../components/Offers/Howitworks";
import Bonus from "../components/Offers/Bonus";
import ManageYourBonuses from "../components/Offers/ManageYourBonuses";
import WhatYouCan from "../components/Offers/WhatYouCan";
import PromotionRules from "../components/Offers/PromotionRules";

function Offers () {
 
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div className="bg-[#F4F4EF]">
            <Main/>
            <TakeAdvantages/>
            <OpenAccounts/>
            <Howitworks/>
            <Bonus/>
            <ManageYourBonuses/>
            <WhatYouCan/>
            <PromotionRules/>
        </div>
    );
}

export default Offers;
