import React from "react";

import { useTranslation, Trans } from "react-i18next";

import metals from '../../img/ProductsOverview/metals.png'
import bg from '../../img/ProductsOverview/main.png'

import Fade from 'react-reveal/Fade'

function Main() {
    
    const { t } = useTranslation();

    return (
        <div className="bg-[#EAEAE5] overflow-hidden relative">
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto mt-[50px] xl:mt-[100px] xl:h-[704px] z-50 relative">
                <p className="uppercase leading-[1.2] xl:text-[90px] text-[48px] font-[600] manrope text-[#FF5131]">{t('products.1')}</p>
                <p className="opacity-[0.7] mt-[20px] max-w-[517px] manrope text-[16px] xl:text-[20px] font-[500] leading-[1.4]"><Trans>{t('products.2')}</Trans></p>
                <p className="roboto leading-[1.4] xl:text-[32px] text-[20px] mt-[20px] xl:mt-[40px]">{t('products.3')}</p>
                <p className="roboto leading-[1.4] xl:text-[32px] text-[20px] mt-[10px] xl:mt-[15px]">{t('products.4')}</p>
                <p className="roboto leading-[1.4] xl:text-[32px] text-[20px] mt-[10px] xl:mt-[15px]">{t('products.5')}</p>
            </div>
            <Fade>
                <img src={bg} alt="" className="absolute top-0 right-0 w-full h-full object-cover"/>
            </Fade>
            <Fade right>
                <img src={metals} alt="" className="xl:absolute top-0 right-0 mt-[40px] xl:mt-0"/>
            </Fade>
           
        </div>
    );
}

export default Main;
