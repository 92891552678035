import React, { useEffect } from "react";

import Main from '../components/Accounts/Main'
import Switcher from "../components/Accounts/Switcher";
import HowCan from "../components/Accounts/HowCan";
import FeesandCharges from "../components/Accounts/FeesandCharges";
import FAQ from "../components/Accounts/FAQ";

function Accounts () {
 
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div className="bg-[#F4F4EF]">
            <Main/>
            <Switcher/>
            <HowCan/>
            <FeesandCharges/>
            <FAQ/>
        </div>
    );
}

export default Accounts;
