import React from "react";

import { useTranslation, Trans } from "react-i18next";

import b1 from '../../img/Offers/bonus1.png'
import b2 from '../../img/Offers/bonus2.png'
import b3 from '../../img/Offers/bonus3.png'

import Fade from 'react-reveal/Fade'

import girl from '../../img/Offers/girl.png'

function Bonus() {
    
    const { t } = useTranslation();

    return (
        <div className="overflow-hidden relative">
           <div className="pb-[20px] xl:pb-0 max-w-[1280px] mx-[20px] xl:mx-auto mt-[80px] xl:mt-[160px] relative xl:h-[504px] rounded-[10px] bg-[#EAEAE5] flex flex-col">
                <p className="manrope text-[20px] xl:text-[48px] text-center leading-[1.2] pt-[20px] xl:pt-[60px]"><span className="font-[500] text-[#FF5131]">50%  </span>{t('offers.19')}</p>
                <div className="grid gap-[50px] xl:gap-0 xl:grid-cols-3 mt-[20px] xl:mt-[40px]">
                    <div className="flex flex-col items-center justify-center">
                        <img src={b1} alt="" />
                        <p className="manrope text-[16px] xl:text-[24px] font-[500] leading-[1.4] mt-[24px]">{t('offers.20')}</p>
                        <p className="manrope text-[14px] xl:text-[16px] font-[400] leading-[1.4] text-[#000000B2] mt-[8px]">{t('offers.21')}</p>
                    </div>
                    <div className="flex flex-col items-center justify-center">
                        <img src={b2} alt="" />
                        <p className="manrope text-[16px] xl:text-[24px] font-[500] leading-[1.4] mt-[24px]">{t('offers.22')}</p>
                        <p className="manrope text-[14px] xl:text-[16px] font-[400] leading-[1.4] text-[#000000B2] mt-[8px]">{t('offers.23')}</p>
                    </div>
                    <div className="flex flex-col items-center justify-center">
                        <img src={b3} alt="" />
                        <p className="manrope text-[16px] xl:text-[24px] font-[500] leading-[1.4] mt-[24px]">{t('offers.24')}</p>
                        <p className="manrope text-[14px] xl:text-[16px] font-[400] leading-[1.4] text-[#000000B2] mt-[8px] text-center">{t('offers.25')}</p>
                    </div>
                </div>
                <a href="https://user.desc-account.icu/signup" className="button_main mt-[60px] mx-auto">
                    <div>
                        <p className="text-white roboto text-[16px] xl:text-[20px] uppercase font-[500]">{t('offers.26')}</p>
                    </div>
                </a>
           </div>
           <div className="max-w-[1280px] mx-[20px] xl:mx-auto mt-[80px] xl:mt-[160px] relative">
                <p className="manrope text-[32px] xl:text-[64px] leading-[1.2] font-[500] text-center spanmain"><Trans>{t('offers.27')}</Trans></p>
                <svg className="absolute top-[70px] left-[29px]" xmlns="http://www.w3.org/2000/svg" width="948" height="413" viewBox="0 0 948 413" fill="none">
                    <path d="M947 0.500244V42.0003C947 47.5231 942.523 52.0003 937 52.0003H11C5.47715 52.0003 1 56.4774 1 62.0003V413" stroke="#FF5131"/>
                </svg>
                <div className="flex flex-col xl:flex-row justify-between mt-[60px] sm:mt-[110px]">
                    <div>
                        <div className="flex gap-[20px] xl:gap-[40px] items-start">
                            <div className="bg-[#F4F4EF] flex-shrink-0 w-[60px] h-[60px] rounded-full border-[#FF5131] border-[10px] relative flex items-center justify-center">
                                <p className="manrope font-[500] text-[16px] xl:text-[23px] text-[#FF5131]">1</p>
                            </div>
                            <div className="">
                                <p className="text-[14px] xl:text-[16px] manrope font-[700] opacity-[0.7]">{t('offers.28')}</p>
                                <p className="text-[14px] xl:text-[16px] manrope font-[500] opacity-[0.7] mt-[6px]">{t('offers.29')}</p>
                            </div>
                        </div>
                        <div className="flex gap-[20px] xl:gap-[40px] items-start mt-[30px]">
                            <div className="bg-[#F4F4EF] flex-shrink-0 w-[60px] h-[60px] rounded-full border-[#FF5131] border-[10px] relative flex items-center justify-center">
                                <p className="manrope font-[500] text-[16px] xl:text-[23px] text-[#FF5131]">2</p>
                            </div>
                            <div className="">
                                <p className="text-[14px] xl:text-[16px] manrope font-[700] opacity-[0.7]">{t('offers.30')}</p>
                                <p className="text-[14px] xl:text-[16px] manrope font-[500] opacity-[0.7] mt-[6px] max-w-[510px]">{t('offers.31')}</p>
                            </div>
                        </div>
                        <div className="flex gap-[20px] xl:gap-[40px] items-start mt-[45px]">
                            <div className="bg-[#F4F4EF] w-[60px] flex-shrink-0 h-[60px] rounded-full border-[#FF5131] border-[10px] relative flex items-center justify-center">
                                <p className="manrope font-[500] text-[16px] xl:text-[23px] text-[#FF5131]">3</p>
                            </div>
                            <div className="">
                                <p className="text-[14px] xl:text-[16px] manrope font-[700] opacity-[0.7]">{t('offers.32')}</p>
                                <p className="text-[14px] xl:text-[16px] manrope font-[500] opacity-[0.7] mt-[6px] max-w-[510px]">{t('offers.33')}</p>
                            </div>
                        </div>
                        <div className="flex gap-[20px] xl:gap-[40px] items-center mt-[20px]">
                            <div className="bg-[#F4F4EF] w-[60px] h-[60px] flex-shrink-0 rounded-full border-[#FF5131] border-[10px] relative flex items-center justify-center">
                                <p className="manrope font-[500] text-[16px] xl:text-[23px] text-[#FF5131]">4</p>
                            </div>
                            <div className="">
                                <p className="text-[14px] xl:text-[16px] manrope font-[700] opacity-[0.7]">{t('offers.34')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-[20px] xl:mt-0">
                        <p className="max-w-[497px] manrope text-[14px] xl:text-[16px] font-[500] opacity-[0.7s]">{t('offers.35')}</p>
                        <div className="xl:w-[497px] xl:h-[261px] rounded-[10px] bg-[rgba(255,81,49,0.1)] mt-[20px] flex flex-col items-center justify-center">
                            <div className="grid grid-cols-2 gap-[20px] mx-[20px] xl:mx-[40px] mt-[20px] xl:mt-0">
                                <p className="manrope opacity-[0.7] font-[500] text-[14px] xl:text-[14px]">{t('offers.36')}</p>
                                <p className="manrope opacity-[0.7] font-[700] text-[14px] xl:text-[14px]">$400</p>
                                <p className="manrope opacity-[0.7] font-[500] text-[14px] xl:text-[14px]">{t('offers.37')}</p>
                                <p className="manrope opacity-[0.7] font-[700] text-[14px] xl:text-[14px]">$200</p>
                                <p className="manrope opacity-[0.7] font-[500] text-[14px] xl:text-[14px]">{t('offers.38')}</p>
                                <p className="manrope opacity-[0.7] font-[700] text-[14px] xl:text-[14px]">$200/2</p>
                            </div>
                            <div className="hidden xl:block xl:w-[421px] h-px bg-[#FF5131] mt-[20px]"></div>
                            <div className="grid grid-cols-2 gap-[20px] mt-[20px] mx-[20px] xl:mx-[40px] mb-[20px] xl:mb-0">
                                <p className="manrope font-[700] text-[14px] xl:text-[14px] text-[#FF5131]">{t('offers.39')}</p>
                                <p className="manrope font-[700] text-[14px] xl:text-[14px] text-[#FF5131]">100 lots</p>
                            </div>
                        </div>
                    </div>
                </div>
           </div>
        </div>
    );
}

export default Bonus;
