import React from "react";

import { useTranslation, Trans } from "react-i18next";

import Fade from 'react-reveal/Fade'

function PromotionRules() {
    
    const { t } = useTranslation();

    return (
        <div className="overflow-hidden relative">
           <div className="max-w-[1280px] mx-[20px] xl:mx-auto mt-[80px] xl:mt-[160px] relative bg-[#EAEAE5] rounded-[10px] xl:h-[1039px] px-[20px] xl:px-[80px]">
                <p className="text-center pt-[20px] xl:pt-[60px] manrope text-[32px] xl:text-[64px] leading-[1.2] font-[500]">{t('offers.56')}</p>
                <div className="flex flex-col xl:flex-row justify-between mt-[20px] xl:mt-[40px] pb-[20px] xl:pb-0">
                    <div className="max-w-[497px]">
                        <p className="manrope text-[14px] xl:text-[16px] opacity-[0.7] font-[500]">• {t('offers.57')}</p>
                        <p className="manrope text-[14px] xl:text-[16px] opacity-[0.7] font-[500] mt-[8px]">• {t('offers.58')}</p>
                        <p className="manrope text-[14px] xl:text-[16px] opacity-[0.7] font-[500] mt-[8px]">• {t('offers.59')}</p>
                        <p className="manrope text-[14px] xl:text-[16px] opacity-[0.7] font-[500] mt-[8px]">• {t('offers.60')}</p>
                        <p className="manrope text-[14px] xl:text-[16px] opacity-[0.7] font-[500] mt-[8px]">• {t('offers.61')}</p>
                        <p className="manrope text-[14px] xl:text-[16px] opacity-[0.7] font-[500] mt-[8px]">• {t('offers.62')}</p>
                        <p className="manrope text-[14px] xl:text-[16px] opacity-[0.7] font-[500] mt-[8px]">• {t('offers.63')}</p>
                        <p className="manrope text-[14px] xl:text-[16px] opacity-[0.7] font-[500] mt-[8px]">• {t('offers.64')}</p>
                        <p className="manrope text-[14px] xl:text-[16px] opacity-[0.7] font-[500] mt-[8px]">• {t('offers.65')}</p>
                    </div>
                    <div className="max-w-[497px]">
                    <p className="manrope text-[14px] xl:text-[16px] opacity-[0.7] font-[500]">• {t('offers.66')}</p>
                        <p className="manrope text-[14px] xl:text-[16px] opacity-[0.7] font-[500] mt-[8px]">• {t('offers.67')}</p>
                        <p className="manrope text-[14px] xl:text-[16px] opacity-[0.7] font-[500] mt-[8px]">• {t('offers.68')}</p>
                        <p className="manrope text-[14px] xl:text-[16px] opacity-[0.7] font-[500] mt-[8px]">• {t('offers.69')}</p>
                        <p className="manrope text-[14px] xl:text-[16px] opacity-[0.7] font-[500] mt-[8px]">• {t('offers.70')}</p>
                        <p className="manrope text-[14px] xl:text-[16px] opacity-[0.7] font-[500] mt-[8px]">• {t('offers.71')}</p>
                        <p className="manrope text-[14px] xl:text-[16px] opacity-[0.7] font-[500] mt-[8px]">• {t('offers.72')}</p>
                        <p className="manrope text-[14px] xl:text-[16px] opacity-[0.7] font-[500] mt-[8px]">• {t('offers.73')}</p>
                        <p className="manrope text-[14px] xl:text-[16px] opacity-[0.7] font-[500] mt-[8px]">• {t('offers.74')}</p>
                    </div>
                </div>
           </div>
        </div>
    );
}

export default PromotionRules;
