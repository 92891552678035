import React from "react";

import { useTranslation, Trans } from "react-i18next";

import Fade from 'react-reveal/Fade'

import girl from '../../img/Offers/girl.png'

function Howitworks() {
    
    const { t } = useTranslation();

    return (
        <div className="overflow-hidden relative">
           <div className="max-w-[1280px] mx-[20px] xl:mx-auto mt-[80px] xl:mt-[160px] relative">
                <div className="flex flex-col xl:flex-row justify-between">
                    <div className="xl:mt-[80px]">
                        <p className="manrope text-[32px] xl:text-[64px] leading-[1.2] font-[500] spanmain"><Trans>{t('offers.14')}</Trans></p>
                        <div className="flex mt-[40px] gap-[40px] items-center">
                            <div className="w-[60px] h-[60px] rounded-full flex items-center justify-center border-[#FF5131] border-[8px] flex-shrink-0">
                                <p className="text-[16px] xl:text-[22.857px] text-[#FF5131] manrope font-[500]">1</p>
                            </div>
                            <p className="text-[14px] xl:text-[16px] manrope opacity-[0.7] font-[500]">{t('offers.15')}</p>
                        </div>
                        <div className="flex mt-[20px] gap-[40px] items-center">
                            <div className="w-[60px] h-[60px] rounded-full flex items-center justify-center border-[#FF5131] border-[8px] flex-shrink-0">
                                <p className="text-[16px] xl:text-[22.857px] text-[#FF5131] manrope font-[500]">2</p>
                            </div>
                            <p className="text-[14px] xl:text-[16px] manrope opacity-[0.7] font-[500] max-w-[510px]">{t('offers.16')}</p>
                        </div>
                        <div className="flex mt-[20px] gap-[40px] items-center">
                            <div className="w-[60px] h-[60px] rounded-full flex items-center justify-center border-[#FF5131] border-[8px] flex-shrink-0">
                                <p className="text-[16px] xl:text-[22.857px] text-[#FF5131] manrope font-[500]">3</p>
                            </div>
                            <p className="text-[14px] xl:text-[16px] manrope opacity-[0.7] font-[500] max-w-[510px]">{t('offers.17')}</p>
                        </div>
                        <div className="flex mt-[20px] gap-[40px] items-center">
                            <div className="w-[60px] h-[60px] rounded-full flex items-center justify-center border-[#FF5131] border-[8px] flex-shrink-0">
                                <p className="text-[16px] xl:text-[22.857px] text-[#FF5131] manrope font-[500]">4</p>
                            </div>
                            <p className="text-[14px] xl:text-[16px] manrope opacity-[0.7] font-[500] max-w-[510px]">{t('offers.18')}</p>
                        </div>
                    </div>
                    <div>
                        <img src={girl} alt="" />
                    </div>
                </div>
           </div>
        </div>
    );
}

export default Howitworks;
