import React, { useEffect } from "react";

import Main from '../components/AboutUs/Main'
import OurValues from "../components/AboutUs/OurValues";

function AboutUs () {
 
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div className="bg-[#F4F4EF]">
            <Main/>
            <OurValues/>
        </div>
    );
}

export default AboutUs;
