import React from "react";

import { useTranslation, Trans } from "react-i18next";

import Fade from 'react-reveal/Fade'

import pig from '../../img/Home/pig.png'

function LowSpreads() {
    
    const { t } = useTranslation();

    return (
        <div className="overflow-hidden relative max-w-[1280px] mx-[20px] xl:mx-auto mt-[80px] xl:mt-[160px]">
            <div className="h-[445px] rounded-[10px] bg-[#FF5131] overflow-hidden relative p-[20px] xl:p-[60px]">
                <p className="manrope leading-[1.2] text-[28px] xl:text-[64px]"><Trans>{t('main.44')}</Trans></p>
                <p className="max-w-[497px] roboto text-[14px] xl:text-[16px] mt-[10px] xl:mt-[30px]">{t('main.45')}</p>
                <Fade right><img src={pig} alt="" className="absolute bottom-0 xl:top-[50px] right-0"/></Fade>
            </div>
        </div>
    );
}

export default LowSpreads;
