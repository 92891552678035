import React, { useEffect } from "react";

import Main from '../components/Home/Main'
import YourSafe from "../components/Home/YourSafe";
import QuicklyAbout from "../components/Home/QuicklyAbout";
import MakeInvesting from "../components/Home/MakeInvesting";
import WhyTrade from "../components/Home/WhyTrade";
import LowSpreads from "../components/Home/LowSpreads";
import TradingForex from "../components/Home/TradingForex";


function Home () {
 
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div className="bg-[#F4F4EF]">
            <Main/>
            <YourSafe/>
            <QuicklyAbout/>
            <MakeInvesting/>
            <WhyTrade/>
            <LowSpreads/>
            <TradingForex/>
        </div>
    );
}

export default Home;
