import React from "react";

import { useTranslation, Trans } from "react-i18next";

import Fade from 'react-reveal/Fade'

import main1 from '../../img/Accounts/main1.png'
import main2 from '../../img/Accounts/main2.png'

function FeesandCharges() {
    
    const { t } = useTranslation();

    return (
        <div className="relative overflow-hidden mt-[80px] xl:mt-[160px]">
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto">
                <div className="flex flex-col xl:flex-row justify-between">
                    <div className="flex flex-col justify-between">
                        <p className="spanmain manrope text-[32px] xl:text-[64px] leading-[1.2]"><Trans>{t('accounts.59')}</Trans></p>
                        <p className="roboto text-[14px] xl:text-[16px] opacity-[0.7] max-w-[600px] mt-[20px] xl:mt-0">{t('accounts.60')}</p>
                        <div className="mt-[20px] xl:mt-[40px] flex items-center justify-center xl:w-[600px] py-[20px] xl:h-[65px] rounded-[10px] bg-[#FF51311A]">
                            <p className="ml-[20px] mr-[20px] xl:mr-[100px] text-[12px] xl:text-[14px] manrope text-[#FF5131] font-[600]">{t('accounts.61')}</p>
                        </div>
                    </div>
                    <div className="mt-[20px] xl:mt-0">
                        <Fade right>
                            <img src={main1} alt="" />
                        </Fade>  
                    </div>
                </div>
                <div className="flex flex-col xl:flex-row-reverse justify-between mt-[80px] xl:mt-[160px]">
                    <div className="flex flex-col justify-between">
                        <p className="spanmain manrope text-[32px] xl:text-[64px] leading-[1.2]"><Trans>{t('accounts.62')}</Trans></p>
                        <p className="roboto text-[14px] xl:text-[16px] opacity-[0.7] max-w-[600px] mt-[20px] xl:mt-0">{t('accounts.63')}</p>
                        <p className="roboto text-[14px] xl:text-[16px] opacity-[0.7] max-w-[600px] mt-[20px] xl:mt-0">{t('accounts.64')}</p>
                    </div>
                    <div className="mt-[20px] xl:mt-0">
                        <Fade left>
                            <img src={main2} alt="" />
                        </Fade>  
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FeesandCharges;
