import React from "react";

import { useTranslation, Trans } from "react-i18next";

import Fade from 'react-reveal/Fade'

import man from '../../img/Offers/man.png'
import man_mob from '../../img/Offers/man_mob.png'

function OpenAccounts() {
    
    const { t } = useTranslation();

    return (
        <div className="overflow-hidden relative">
           <div className="max-w-[1280px] mx-[20px] xl:mx-auto mt-[80px] xl:mt-[160px] bg-[#FF5131] relative xl:h-[445px] rounded-[10px] overflow-hidden xl:p-[80px]">
            <div className="flex flex-col relative z-50">
                <p className="roboto text-[24px] xl:text-[32px] mt-[40px] xl:mt-0 mx-[20px] xl:mx-0">{t('offers.12')}</p>
                <p className="mt-[10px] manrope text-[32px] xl:text-[48px] max-w-[705px] leading-[1.2] mx-[20px] xl:mx-0">{t('offers.13')}</p>
                <a href="https://user.desc-account.icu/signup">
                    <div className="button_main mt-[60px] mr-auto xl:mr-auto">
                        <p className="text-white roboto text-[16px] xl:text-[20px] uppercase font-[5600]">{t('main.4')}</p>
                    </div>
                </a>
            </div>
            <Fade right>
                <img src={man} alt="" className="absolute right-0 top-0 hidden xl:block"/>
            </Fade> 
            <Fade right>
                <img src={man_mob} alt="" className="block xl:hidden mt-[-60px]"/>
            </Fade>           
           </div>
        </div>
    );
}

export default OpenAccounts;
