import React from 'react';

import { useTranslation, Trans } from "react-i18next";

import logo from '../img/Home/logo.png'

function Footer() {

  const { t } = useTranslation();

  const currentYear = new Date().getFullYear();

  return (
    <div className='bg-[#F4F4EF] z-10 relative'>
      <div className='flex flex-col xl:flex-row container mx-auto pt-[160px]'>
        <img src={logo} alt="" className='w-[80px] h-[40px] ml-[30px] xl:ml-0'/>
        <div className='ml-[30px] xl:ml-[125px] gap-[15px] flex flex-col mt-[30px] xl:mt-0'>
          <p className='text-[#000000B2] roboto text-[14px] xl:text-[16px] font-normal'>{t('footer1')} +441863440257</p>
          <div>
            <p className='text-[#000000B2] roboto text-[14px] xl:text-[16px] font-normal'>{t('footer2')} 100G PASIR PANJANG ROAD #06-08, INTERLOCAL CENTRE</p>
            <p className='text-[#000000B2] roboto text-[14px] xl:text-[16px] font-normal'>SINGAPORE</p>
          </div>
          <p className='text-[#000000B2] roboto text-[14px] xl:text-[16px] font-normal'>{t('footer3')}support@investment-pte.com</p>
        </div>
        <div className='ml-[30px] xl:ml-[450px] gap-[15px] flex flex-col mt-[15px] xl:mt-0'>
          <a href="/terms.pdf"><p className='text-[#000000B2] roboto text-[14px] xl:text-[16px] font-normal'>{t('footer4')}</p></a>
          <a href="/policy.pdf"><p className='text-[#000000B2] roboto text-[14px] xl:text-[16px] font-normal'>{t('footer5')}</p></a>
          <a href="/aml.pdf"><p className='text-[#000000B2] roboto text-[14px] xl:text-[16px] font-normal'>{t('footer6')}</p></a>
        </div>
      </div>
      <div className='flex flex-col container mx-auto pl-[30px] xl:pl-[205px] pt-[40px]'>
        <p className='text-[#000000B2] roboto text-[12px] font-normal'>{t('footer7')}</p>
        <p className='text-[#000000B2] roboto text-[12px] font-light mt-[6px] pr-[30px] xl:pr-0'><Trans>{t('footer8')}</Trans></p>
        <p className='flex justify-end pb-[150px] mt-[14px] roboto text-[16px] text-[#000000B2] pr-[30px] xl:pr-0'>Performance investment pte ltd © {currentYear}. All rights reserved.</p>      
      </div>
    </div>
  );
}

export default Footer;