import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import check from '../../img/Accounts/check.png'; // Assuming you have this image

const data = [
    {
        title: 'accounts.5',
        text1: 'Trial period',
        text2: 'Standard Settings',
        text3: <svg className="mx-auto" xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none"><path d="M19 13.1016H13H11H5V11.1016H11H13H19V13.1016Z" fill="#FF5131"/></svg>,
        text4: <svg className="mx-auto" xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none"><path d="M19 13.1016H13H11H5V11.1016H11H13H19V13.1016Z" fill="#FF5131"/></svg>,
        text5: <svg className="mx-auto" xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none"><path d="M19 13.1016H13H11H5V11.1016H11H13H19V13.1016Z" fill="#FF5131"/></svg>,
        text6: <svg className="mx-auto" xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none"><path d="M19 13.1016H13H11H5V11.1016H11H13H19V13.1016Z" fill="#FF5131"/></svg>,
        text7: '40%',
    },
    {
        title: 'accounts.6',
        text1: <svg className="mx-auto" xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none"><path d="M19 13.1016H13V19.1016H11V13.1016H5V11.1016H11V5.10156H13V11.1016H19V13.1016Z" fill="#298E52"/></svg>,
        text2: 'Standard Settings',
        text3: <svg className="mx-auto" xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none"><path d="M19 13.1016H13V19.1016H11V13.1016H5V11.1016H11V5.10156H13V11.1016H19V13.1016Z" fill="#298E52"/></svg>,
        text4: <svg className="mx-auto" xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none"><path d="M19 13.1016H13H11H5V11.1016H11H13H19V13.1016Z" fill="#FF5131"/></svg>,
        text5: <svg className="mx-auto" xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none"><path d="M19 13.1016H13H11H5V11.1016H11H13H19V13.1016Z" fill="#FF5131"/></svg>,
        text6: <svg className="mx-auto" xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none"><path d="M19 13.1016H13H11H5V11.1016H11H13H19V13.1016Z" fill="#FF5131"/></svg>,
        text7: '50%',
    },
    {
        title: 'accounts.7',
        text1: <svg className="mx-auto" xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none"><path d="M19 13.1016H13V19.1016H11V13.1016H5V11.1016H11V5.10156H13V11.1016H19V13.1016Z" fill="#298E52"/></svg>,
        text2: 'Pro Settings',
        text3: <svg className="mx-auto" xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none"><path d="M19 13.1016H13V19.1016H11V13.1016H5V11.1016H11V5.10156H13V11.1016H19V13.1016Z" fill="#298E52"/></svg>,
        text4: <svg className="mx-auto" xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none"><path d="M19 13.1016H13V19.1016H11V13.1016H5V11.1016H11V5.10156H13V11.1016H19V13.1016Z" fill="#298E52"/></svg>,
        text5: <svg className="mx-auto" xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none"><path d="M19 13.1016H13V19.1016H11V13.1016H5V11.1016H11V5.10156H13V11.1016H19V13.1016Z" fill="#298E52"/></svg>,
        text6: <svg className="mx-auto" xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none"><path d="M19 13.1016H13H11H5V11.1016H11H13H19V13.1016Z" fill="#FF5131"/></svg>,
        text7: '70%',
    },
    {
        title: 'accounts.8',
        text1: <svg className="mx-auto" xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none"><path d="M19 13.1016H13V19.1016H11V13.1016H5V11.1016H11V5.10156H13V11.1016H19V13.1016Z" fill="#298E52"/></svg>,
        text2: 'Pro Settings',
        text3: <svg className="mx-auto" xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none"><path d="M19 13.1016H13V19.1016H11V13.1016H5V11.1016H11V5.10156H13V11.1016H19V13.1016Z" fill="#298E52"/></svg>,
        text4: <svg className="mx-auto" xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none"><path d="M19 13.1016H13V19.1016H11V13.1016H5V11.1016H11V5.10156H13V11.1016H19V13.1016Z" fill="#298E52"/></svg>,
        text5: <svg className="mx-auto" xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none"><path d="M19 13.1016H13V19.1016H11V13.1016H5V11.1016H11V5.10156H13V11.1016H19V13.1016Z" fill="#298E52"/></svg>,
        text6: <svg className="mx-auto" xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none"><path d="M19 13.1016H13V19.1016H11V13.1016H5V11.1016H11V5.10156H13V11.1016H19V13.1016Z" fill="#298E52"/></svg>,
        text7: '80%',
    },
];

const featureNames = [
    'Expert guidance',
    'Automated trading',
    'Trial period',
    'Webinars',
    'Investment Plan',
    'Trust management',
    'Bonus'
];

function Switcher() {
    const { t } = useTranslation();
    const [active, setActive] = useState(0);

    return (
        <div className="relative overflow-hidden mt-[80px] xl:mt-[160px]">
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col">
                
                {/* Mobile View - Switcher */}
                <div className="block xl:hidden">
                    <div className="flex flex-wrap xl:flex-col gap-[20px]">
                        {data.map((faq, index) => (
                            <div key={index} className="flex items-center cursor-pointer mx-auto" onClick={() => setActive(index)}>
                                <div className={`hidden xl:block w-[18px] h-[18px] rounded-full ${active === index ? 'bg-[#FF5131]' : 'border border-[#00000033]'}`}></div>
                                <div className={`hidden xl:block w-[31px] h-px ${active === index ? 'bg-[#FF5131]' : 'bg-[#00000033]'}`}></div>
                                <div className={`flex items-center justify-center w-[100px] xl:w-[187px] h-[58px] rounded-[10px] transition-all ${active === index ? 'bg-[#FF5131]' : 'border border-[#00000033]'}`}>
                                    <p className={`manrope text-[16px] xl:text-[20px] font-[500] ${active === index ? 'text-white' : 'text-black'}`}>{t(faq.title)}</p>
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* Active selection details */}
                    <div className="xl:w-[497px] h-[462px] rounded-[10px] bg-[#EAEAE5] p-[40px] mt-[20px]">
                        <p className="text-[#FF5131] manrope text-[20px] xl:text-[36px] font-[600] leading-[1.2]">{t(data[active].title)} {t('accounts.9')}</p>
                        <div className="grid grid-cols-2 mt-[30px]">
                            <p className="font-[300] roboto">{t('accounts.10')}</p>
                            <p className="font-[400] roboto mx-auto">{data[active].text1}</p>
                        </div>
                        <div className="grid grid-cols-2 mt-[12px]">
                            <p className="font-[300] roboto">{t('accounts.11')}</p>
                            <p className="font-[400] roboto mx-auto">{data[active].text2}</p>
                        </div>
                        <div className="grid grid-cols-2 mt-[12px]">
                            <p className="font-[300] roboto">{t('accounts.12')}</p>
                            <p className="font-[400] roboto mx-auto">{data[active].text3}</p>
                        </div>
                        <div className="grid grid-cols-2 mt-[12px]">
                            <p className="font-[300] roboto">{t('accounts.13')}</p>
                            <p className="font-[400] roboto mx-auto">{data[active].text4}</p>
                        </div>
                        <div className="grid grid-cols-2 mt-[12px]">
                            <p className="font-[300] roboto">{t('accounts.14')}</p>
                            <p className="font-[400] roboto mx-auto">{data[active].text5}</p>
                        </div>
                        <div className="grid grid-cols-2 mt-[12px]">
                            <p className="font-[300] roboto">{t('accounts.15')}</p>
                            <p className="font-[400] roboto mx-auto">{data[active].text6}</p>
                        </div>
                        <div className="grid grid-cols-2 mt-[12px]">
                            <p className="font-[300] roboto">{t('accounts.16')}</p>
                            <p className="font-[400] roboto mx-auto">{data[active].text7}</p>
                        </div>
                    </div>
                </div>

                {/* Desktop View - Inverted Table */}
                <div className="hidden xl:block">
                    <table className="min-w-full text-center table-auto">
                        <thead>
                            <tr className="bg-[#EAEAE5]">
                                <th className="p-4 text-[#000000B2] roboto">Account type</th>
                                {data.map((row, index) => (
                                    <th key={index} className="p-4 text-[#FF5131] manrope font-[800] text-[20px]">{t(row.title)}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {featureNames.map((feature, i) => (
                                <tr key={i}>
                                    <td className="p-4 roboto text-[#000000B2]">{t(`accounts.${10 + i}`)}</td>
                                    {data.map((row, j) => (
                                        <td key={j} className="p-4 roboto text-[#000000B2]">{row[`text${i + 1}`]}</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default Switcher;
