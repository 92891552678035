import React from "react";

import { useTranslation, Trans } from "react-i18next";

import phone from '../../img/ProductsOverview/phone.png'

import Fade from 'react-reveal/Fade'

function GOtothenextlevel() {
    
    const { t } = useTranslation();

    return (
        <div className="bg-[#F4F4EF] overflow-hidden relative mt-[80px] xl:mt-[160px]">
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col-reverse xl:flex-row justify-between">
                <div className="mt-[20px] xl:mt-0">
                    <Fade left><img src={phone} alt="" /></Fade>
                </div>
                <div>
                    <p className="roboto leading-[1.4] xl:text-[40px] text-[24px] xl:mt-[30px]">{t('products.6')}</p>
                    <p className="leading-[1.2] xl:text-[64px] text-[32px] font-[500] manrope text-[#FF5131]">{t('products.7')}</p>
                    <p className="max-w-[600px] mt-[20px] xl:mt-[40px] font-[500] manrope text-[16px] xl:text-[20px] opacity-[0.7]">{t('products.8')}</p>
                    <a href="https://user.desc-account.icu/signup">
                        <div className="button_main mt-[40px]">
                            <p className="text-white roboto text-[16px] xl:text-[20px] uppercase font-[5600]">{t('main.4')}</p>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default GOtothenextlevel;
