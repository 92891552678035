import React, { useEffect } from "react";

import Main from '../components/ContactUs/Main'
import TradeMultiAward from "../components/ContactUs/TradeMultiAward";

function ContactUs () {
 
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div className="bg-[#F4F4EF]">
            <Main/>
            <TradeMultiAward/>
        </div>
    );
}

export default ContactUs;
