import React from "react";

import { useTranslation, Trans } from "react-i18next";

import Fade from 'react-reveal/Fade'

import group from '../../img/Home/group.png'

function WhyTrade() {
    
    const { t } = useTranslation();

    return (
        <div className="overflow-hidden relative max-w-[1280px] mx-[20px] xl:mx-auto mt-[80px] xl:mt-[160px]">
           <p className="manrope text-[28px] xl:text-[64px] leading-[1.2] spanmain"><Trans>{t('main.38')}</Trans></p>
           <div className="flex justify-between mt-[60px]">
                <div className="flex flex-col pb-[20px] xl:pb-0">
                    <div className="flex relative">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                            <circle cx="9" cy="9" r="9" fill="#FF5131"/>
                        </svg>
                        <svg className="mt-[7px]" xmlns="http://www.w3.org/2000/svg" width="726" height="78" viewBox="0 0 726 78" fill="none">
                            <path d="M725 77L600 1H0" stroke="url(#paint0_linear_2531_67)"/>
                            <defs>
                                <linearGradient id="paint0_linear_2531_67" x1="0" y1="1" x2="725" y2="1" gradientUnits="userSpaceOnUse">
                                <stop/>
                                <stop offset="1" stop-opacity="0"/>
                                </linearGradient>
                            </defs>
                        </svg>
                        <div className="flex items-center xl:w-[458px] h-[52px] rounded-[10px] border border-black absolute left-[50px] top-[-18px] bg-[#F4F4EF]">
                            <p className="mx-[10px] xl:mx-[40px] manrope text-[16px] xl:text-[20px] font-[500] leading-[1.2]"><Trans>{t('main.39')}</Trans></p>
                        </div>
                    </div>
                    <div className="flex relative">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                            <circle cx="9" cy="9" r="9" fill="#FF5131"/>
                        </svg>
                        <svg className="mt-[7px]" xmlns="http://www.w3.org/2000/svg" width="726" height="78" viewBox="0 0 726 78" fill="none">
                            <path d="M725 77L600 1H0" stroke="url(#paint0_linear_2531_69)"/>
                            <defs>
                                <linearGradient id="paint0_linear_2531_69" x1="0" y1="1" x2="725" y2="1" gradientUnits="userSpaceOnUse">
                                <stop/>
                                <stop offset="1" stop-opacity="0"/>
                                </linearGradient>
                            </defs>
                        </svg>
                        <div className="flex items-center xl:w-[458px] h-[52px] rounded-[10px] border border-black absolute left-[50px] top-[-18px] bg-[#F4F4EF]">
                            <p className="mx-[10px] xl:mx-[40px] manrope text-[16px] xl:text-[20px] font-[500] leading-[1.2]"><Trans>{t('main.40')}</Trans></p>
                        </div>
                    </div>
                    <div className="flex relative">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                            <circle cx="9" cy="9" r="9" fill="#FF5131"/>
                        </svg>
                        <svg className="mt-[7px]" xmlns="http://www.w3.org/2000/svg" width="721" height="2" viewBox="0 0 721 2" fill="none">
                            <path d="M721 1H600H0" stroke="url(#paint0_linear_2531_68)"/>
                            <defs>
                                <linearGradient id="paint0_linear_2531_68" x1="0" y1="1" x2="721" y2="1.0002" gradientUnits="userSpaceOnUse">
                                <stop/>
                                <stop offset="1" stop-opacity="0"/>
                                </linearGradient>
                            </defs>
                        </svg>
                        <div className="flex items-center xl:w-[458px] h-[52px] rounded-[10px] border border-black absolute left-[50px] top-[-18px] bg-[#F4F4EF]">
                            <p className="mx-[10px] xl:mx-[40px] manrope text-[16px] xl:text-[20px] font-[500] leading-[1.2]"><Trans>{t('main.41')}</Trans></p>
                        </div>
                    </div>
                    <div className="flex relative">
                        <svg className="mt-[68px]" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                            <circle cx="9" cy="9" r="9" fill="#FF5131"/>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="726" height="78" viewBox="0 0 726 78" fill="none">
                            <path d="M725 1L600 77H0" stroke="url(#paint0_linear_2531_71)"/>
                            <defs>
                                <linearGradient id="paint0_linear_2531_71" x1="0" y1="77" x2="725" y2="77" gradientUnits="userSpaceOnUse">
                                <stop/>
                                <stop offset="1" stop-opacity="0"/>
                                </linearGradient>
                            </defs>
                        </svg>
                        <div className="flex items-center xl:w-[458px] h-[52px] rounded-[10px] border border-black absolute left-[50px] top-[50px] bg-[#F4F4EF]">
                            <p className="mx-[10px] xl:mx-[40px] manrope text-[16px] xl:text-[20px] font-[500] leading-[1.2]"><Trans>{t('main.42')}</Trans></p>
                        </div>
                    </div>
                    <div className="flex relative">
                        <svg className="mt-[68px]" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                            <circle cx="9" cy="9" r="9" fill="#FF5131"/>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="726" height="78" viewBox="0 0 726 78" fill="none">
                            <path d="M725 1L600 77H0" stroke="url(#paint0_linear_2531_70)"/>
                            <defs>
                                <linearGradient id="paint0_linear_2531_70" x1="0" y1="77" x2="725" y2="77" gradientUnits="userSpaceOnUse">
                                <stop/>
                                <stop offset="1" stop-opacity="0"/>
                                </linearGradient>
                            </defs>
                        </svg>
                        <div className="flex items-center xl:w-[458px] h-[52px] rounded-[10px] border border-black absolute left-[50px] top-[50px] bg-[#F4F4EF]">
                            <p className="mx-[10px] xl:mx-[40px] manrope text-[16px] xl:text-[20px] font-[500] leading-[1.2]"><Trans>{t('main.43')}</Trans></p>
                        </div>
                    </div>
                </div>
                <div className="xl:mt-[-70px] hidden md:block">
                    <Fade right> 
                        <img src={group} alt="" />
                    </Fade>
                </div>
           </div>
           <div className="mt-[40px] md:hidden block">
                <Fade right> 
                    <img src={group} alt="" />
                </Fade>
            </div>
        </div>
    );
}

export default WhyTrade;
