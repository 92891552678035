import React, { useEffect, useState } from 'react';

import { useTranslation, Trans } from "react-i18next";

import notebook from '../../img/ProductsOverview/notebook.png'

import { useInView } from 'react-intersection-observer';

import Fade from 'react-reveal/Fade'

function Start() {
    
    const { t } = useTranslation();

    const [step, setStep] = useState(1);

    useEffect(() => {
        const timer = setInterval(() => {
            setStep(prevStep => (prevStep < 3 ? prevStep + 1 : 3));
        }, 1000); // Change step every 1 second

        return () => clearInterval(timer);
    }, []);

    return (
        <div className="bg-[#F4F4EF] overflow-hidden relative mt-[80px] xl:mt-[160px] max-w-[1280px] mx-[20px] xl:mx-auto">
            <div className="xl:h-[628px] rounded-[10px] bg-[#EAEAE5] xl:p-[40px] relative">
                <p className="manrope xl:text-[64px] text-[32px] font-[500] leading-[1.2] text-[#FF5131] mx-[20px] xl:mx-0 pt-[20px] xl:pt-0">{t('products.45')}</p>
                <p className="roboto leading-[1.4] xl:text-[40px] text-[24px] mt-[10px] ml-[200px] mx-[20px] xl:mx-0">{t('products.46')}</p>
                <div className="flex flex-col gap-[30px] xl:gap-0 xl:flex-row mt-[20px] xl:mt-[100px] items-center z-50 relative">
                    <div className="w-[18px] h-[18px] rounded-full bg-[#FF5131] hidden xl:block"></div>
                    <div className="w-[31px] h-px bg-[#FF5131] hidden xl:block"></div>
                    <div className='flex flex-col items-center'>
                        <div className='w-[100px] xl:w-[160px] h-[100px] xl:h-[160px] rounded-full border-[#FF5131] border-[15px] flex items-center justify-center'>
                            <p className='text-[#FF5131] text-[36px] xl:text-[64px] font-[500]'>1</p>
                        </div>
                        <div className='xl:absolute flex flex-col items-center top-[180px]'>
                            <p className='manrope text-[16px] xl:text-[20px]'>{t('products.47')}</p>
                            <p className='mt-[18px] max-w-[192px] text-[#000000B2] text-center'>{t('products.48')}</p>
                        </div>
                    </div>
                    <div className="w-[86px] h-px bg-[#FF5131] hidden xl:block"></div>
                    <div className='flex flex-col items-center'>
                        <div className='w-[100px] xl:w-[160px] h-[100px] xl:h-[160px] rounded-full border-[#FF5131] border-[15px] flex items-center justify-center'>
                            <p className='text-[#FF5131] text-[36px] xl:text-[64px] font-[500]'>2</p>
                        </div>
                        <div className='xl:absolute flex flex-col items-center top-[180px]'>
                            <p className='manrope text-[16px] xl:text-[20px]'>{t('products.49')}</p>
                            <p className='mt-[18px] max-w-[192px] text-[#000000B2] text-center'>{t('products.50')}</p>
                        </div>
                    </div>
                    <div className="w-[86px] h-px bg-[#FF5131] hidden xl:block"></div>
                    <div className='flex flex-col items-center'>
                        <div className='w-[100px] xl:w-[160px] h-[100px] xl:h-[160px] rounded-full border-[#FF5131] border-[15px] flex items-center justify-center'>
                            <p className='text-[#FF5131] text-[36px] xl:text-[64px] font-[500]'>3</p>
                        </div>
                        <div className='xl:absolute flex flex-col items-center top-[180px]'>
                            <p className='manrope text-[16px] xl:text-[20px]'>{t('products.51')}</p>
                            <p className='mt-[18px] max-w-[192px] text-[#000000B2] text-center'>{t('products.52')}</p>
                        </div>
                    </div>
                </div>
                <Fade right><img src={notebook} className="mt-[20px] xl:mt-0 xl:absolute bottom-0 right-0" alt="" /></Fade>
            </div>
        </div>
    );
}

export default Start;
