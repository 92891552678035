import React from "react";

import { useTranslation } from "react-i18next";

function Main() {
    
    const { t } = useTranslation();

    return (
        <div className="bg-[#F4F4EF] overflow-hidden relative">
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto pt-[40px] xl:pt-[100px] relative z-50 flex flex-col xl:flex-row justify-between">
                <div className="xl:mt-[25px] flex flex-col justify-between">
                    <p className="manrope text-[32px] xl:text-[48px] uppercase font-[600] leading-[1.2] text-[#FF5131]">{t('contactus.1')}</p>
                    <p className="mt-[20px] manrope text-[16px] xl:text-[20px] leading-[1.4] opacity-[0.7] text-black xl:max-w-[497px]">{t('contactus.2')}</p>
                    <div className="xl:w-[497px] h-[250px] md:h-[234px] rounded-[10px] bg-[#FF51311A] flex flex-col justify-center px-[20px] mt-[20px] xl:mt-0">
                        <div className="flex flex-col md:flex-row gap-[10px] xl:gap-[100px]">
                            <div className="flex flex-col">
                                <div className="flex items-center gap-[6px]">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M20.487 17.1402L16.422 13.4442C16.2299 13.2696 15.9774 13.1764 15.7178 13.1845C15.4583 13.1925 15.212 13.3011 15.031 13.4872L12.638 15.9482C12.062 15.8382 10.904 15.4772 9.71201 14.2882C8.52001 13.0952 8.15901 11.9342 8.05201 11.3622L10.511 8.96824C10.6974 8.78737 10.8061 8.54106 10.8142 8.28145C10.8222 8.02183 10.7289 7.76928 10.554 7.57724L6.85901 3.51324C6.68405 3.3206 6.44089 3.20374 6.18116 3.1875C5.92143 3.17125 5.66561 3.2569 5.46801 3.42624L3.29801 5.28724C3.12512 5.46075 3.02193 5.69169 3.00801 5.93624C2.99301 6.18624 2.70701 12.1082 7.29901 16.7022C11.305 20.7072 16.323 21.0002 17.705 21.0002C17.907 21.0002 18.031 20.9942 18.064 20.9922C18.3085 20.9786 18.5393 20.8749 18.712 20.7012L20.572 18.5302C20.742 18.3333 20.8283 18.0776 20.8124 17.8179C20.7965 17.5582 20.6798 17.315 20.487 17.1402Z" fill="#FF5131"/>
                                    </svg>
                                    <p className="manrope text-[14px] xl:text-[16px] font-[700] text-[#FF5131]">{t('contactus.3')}</p>
                                </div>
                                <p className="manrope text-[14px] xl:text-[16px] opacity-[0.7] text-black mt-[8px]">+441312028665</p>
                            </div>
                            <div className="flex flex-col mt-[10px] md:mt-0">
                                <div className="flex items-center gap-[6px]">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <g clip-path="url(#clip0_2531_641)">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.172 11.334L10.002 13.269L12.73 11.387L18.845 17.42C18.6837 17.4727 18.513 17.4993 18.333 17.5H1.667C1.447 17.5 1.237 17.457 1.044 17.38L7.172 11.334ZM20 6.376V15.833C20 16.08 19.946 16.314 19.85 16.525L13.856 10.611L20 6.376ZM0 6.429L6.042 10.561L0.106 16.419C0.0364628 16.2314 0.000578992 16.033 0 15.833L0 6.429ZM18.333 2.5C19.253 2.5 20 3.246 20 4.167V4.753L9.998 11.648L0 4.81V4.167C0 3.247 0.746 2.5 1.667 2.5H18.333Z" fill="#FF5131"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_2531_641">
                                            <rect width="20" height="20" fill="white"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <p className="manrope text-[14px] xl:text-[16px] font-[700] text-[#FF5131]">{t('contactus.4')}</p>
                                </div>
                                <p className="manrope text-[14px] xl:text-[16px] opacity-[0.7] text-black mt-[8px]">support@investment-pte.com</p>
                            </div>
                        </div>
                        <div className="flex flex-col mt-[10px] md:mt-[50px]">
                            <div className="flex items-center gap-[6px]">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M12 11.5C11.337 11.5 10.7011 11.2366 10.2322 10.7678C9.76339 10.2989 9.5 9.66304 9.5 9C9.5 8.33696 9.76339 7.70107 10.2322 7.23223C10.7011 6.76339 11.337 6.5 12 6.5C12.663 6.5 13.2989 6.76339 13.7678 7.23223C14.2366 7.70107 14.5 8.33696 14.5 9C14.5 9.3283 14.4353 9.65339 14.3097 9.95671C14.1841 10.26 13.9999 10.5356 13.7678 10.7678C13.5356 10.9999 13.26 11.1841 12.9567 11.3097C12.6534 11.4353 12.3283 11.5 12 11.5ZM12 2C10.1435 2 8.36301 2.7375 7.05025 4.05025C5.7375 5.36301 5 7.14348 5 9C5 14.25 12 22 12 22C12 22 19 14.25 19 9C19 7.14348 18.2625 5.36301 16.9497 4.05025C15.637 2.7375 13.8565 2 12 2Z" fill="#FF5131"/>
                                </svg>
                                <p className="manrope text-[14px] xl:text-[16px] font-[700] text-[#FF5131]">{t('contactus.5')}</p>
                            </div>
                            <p className="manrope text-[14px] xl:text-[16px] opacity-[0.7] text-black mt-[8px]">100G PASIR PANJANG ROAD #06-08, INTERLOCAL CENTRE<br/>SINGAPORE</p>
                        </div>
                    </div>
                </div> 
                <div className="h-[522px] xl:w-[600px] rounded-[10px] bg-[#EAEAE5] flex flex-col items-center justify-center mt-[20px] xl:mt-0">
                    <p className="text-[16px] xl:text-[24px] manrope leading-[1.2] font-[500]">{t('contactus.6')}</p>
                    <p className="text-[20px] xl:text-[32px] manrope leading-[1.2] font-[500] text-[#FF5131] mt-[6px]">{t('contactus.7')}</p>
                    <div className="px-[20px] xl:px-[100px] w-full mt-[40px]">
                        <input placeholder={t('contactus.8')} type="text" className="h-[58px] w-full rounded-[10px] bg-[#F4F4EF] border border-[#00000033] outline-none p-[20px] manrope text-[16px] xl:text-[20px]"/>
                    </div>
                    <div className="px-[20px] xl:px-[100px] w-full mt-[10px]">
                        <input placeholder={t('contactus.9')} type="text" className="h-[58px] w-full rounded-[10px] bg-[#F4F4EF] border border-[#00000033] outline-none p-[20px] manrope text-[16px] xl:text-[20px]"/>
                    </div>
                    <div className="px-[20px] xl:px-[100px] w-full mt-[10px]">
                        <input placeholder={t('contactus.10')} type="text" className="h-[58px] w-full rounded-[10px] bg-[#F4F4EF] border border-[#00000033] outline-none p-[20px] manrope text-[16px] xl:text-[20px]"/>
                    </div>
                    <div className="px-[20px] xl:px-[100px] w-full mt-[10px]">
                        <input placeholder={t('contactus.11')} type="text" className="h-[58px] w-full rounded-[10px] bg-[#F4F4EF] border border-[#00000033] outline-none p-[20px] manrope text-[16px] xl:text-[20px]"/>
                    </div>
                    <div className="bg-black rounded-[40px] px-[30px] py-[15px] mt-[20px] cursor-pointer">
                        <p className="text-white uppercase font-[500] text-[16px] xl:text-[20px] roboto">{t('contactus.12')}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Main;

