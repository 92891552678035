import React from "react";

import { useTranslation, Trans } from "react-i18next";

import check from '../../img/Home/check.png'

function YourSafe() {
    
    const { t } = useTranslation();

    return (
        <div className="overflow-hidden relative max-w-[1280px] mx-[20px] xl:mx-auto mt-[80px] xl:mt-[160px]">
            <p className="manrope text-[28px] xl:text-[64px] leading-[1.2] spanmain"><Trans>{t('main.5')}</Trans></p>
            <div className="flex flex-col xl:flex-row justify-between mt-[30px] xl:mt-[60px]">
                <div className="flex gap-[30px] items-center">
                    <img src={check} alt="" />
                    <p className="roboto text-[14px] xl:text-[16px] text-[#000000B3] xl:w-[529px]">{t('main.6')}</p>
                </div>
                <div className="flex gap-[30px] items-center mt-[20px] xl:mt-0">
                    <img src={check} alt="" />
                    <p className="roboto text-[14px] xl:text-[16px] text-[#000000B3] xl:w-[529px]">{t('main.7')}</p>
                </div>
            </div>
            <div className="flex flex-col xl:flex-row justify-between mt-[20px] xl:mt-[25px]">
                <div className="flex gap-[30px] items-center">
                    <img src={check} alt="" />
                    <p className="roboto text-[14px] xl:text-[16px] text-[#000000B3] xl:w-[529px]">{t('main.8')}</p>
                </div>
                <div className="flex gap-[30px] items-center mt-[20px] xl:mt-0">
                    <img src={check} alt="" />
                    <p className="roboto text-[14px] xl:text-[16px] text-[#000000B3] xl:w-[529px]">{t('main.9')}</p>
                </div>
            </div>
            <div className="flex flex-col xl:flex-row justify-between mt-[20px] xl:mt-[25px]">
                <div className="flex gap-[30px] items-center">
                    <img src={check} alt="" />
                    <p className="roboto text-[14px] xl:text-[16px] text-[#000000B3] xl:w-[529px]">{t('main.10')}</p>
                </div>
                <div className="flex gap-[30px] items-center mt-[20px] xl:mt-0">
                    <img src={check} alt="" />
                    <p className="roboto text-[14px] xl:text-[16px] text-[#000000B3] xl:w-[529px]">{t('main.11')}</p>
                </div>
            </div>
            <div className="flex flex-col xl:flex-row justify-between mt-[20px] xl:mt-[25px]">
                <div className="flex gap-[30px] items-center">
                    <img src={check} alt="" />
                    <p className="roboto text-[14px] xl:text-[16px] text-[#000000B3] xl:w-[529px]">{t('main.12')}</p>
                </div>
                <div className="flex gap-[30px] items-center mt-[20px] xl:mt-0">
                    <img src={check} alt="" />
                    <p className="roboto text-[14px] xl:text-[16px] text-[#000000B3] xl:w-[529px]">{t('main.13')}</p>
                </div>
            </div>
        </div>
    );
}

export default YourSafe;
