import React from "react";

import { useTranslation, Trans } from "react-i18next";

import group from '../../img/Offers/group.png'

import Fade from 'react-reveal/Fade'

function ManageYourBonuses() {
    
    const { t } = useTranslation();

    return (
        <div className="overflow-hidden relative">
           <div className="max-w-[1280px] mx-[20px] xl:mx-auto mt-[80px] xl:mt-[160px] relative">
            <div className="flex flex-col xl:flex-row justify-between">
                <div>
                    <p className="text-[20px] xl:text-[40px] manrope font-[500] leading-[1.2]">{t('offers.40')}</p>
                    <p className="text-[32px] xl:text-[64px] manrope font-[500] leading-[1.2] text-[#FF5131]">{t('offers.41')}</p>
                    <p className="text-[16px] xl:text-[24px] manrope font-[500] leading-[1.4] mt-[20px] max-w-[539px]">{t('offers.42')}</p>
                    <p className="text-[14px] xl:text-[16px] manrope font-[500] leading-[1.4] mt-[20px] max-w-[539px]">{t('offers.43')}</p>
                    <p className="text-[14px] xl:text-[16px] manrope font-[500] leading-[1.5] mt-[40px] max-w-[497px] opacity-[0.7]">{t('offers.44')}</p>
                    <p className="text-[14px] xl:text-[16px] manrope font-[500] leading-[1.5] mt-[20px] max-w-[497px] opacity-[0.7]">{t('offers.45')}</p>
                </div>
                <div className="mt-[20px] xl:mt-[40px]">
                    <Fade right>
                        <img src={group} alt="" />
                    </Fade>
                </div>
            </div>
           </div>
        </div>
    );
}

export default ManageYourBonuses;
