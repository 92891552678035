import React from "react";

import { useTranslation, Trans } from "react-i18next";

import Fade from 'react-reveal/Fade'

import two from '../../img/Offers/two.png'

function TakeAdvantages() {
    
    const { t } = useTranslation();

    return (
        <div className="overflow-hidden relative">
           <div className="max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col xl:flex-row justify-between mt-[80px] xl:mt-[160px]">
                <div className="xl:mt-[120px]">
                    <p className="manrope text-[20px] xl:text-[36px] leading-[1.2] font-[500]">{t('offers.6')}</p>
                    <p className="manrope text-[24px] xl:text-[48px] leading-[1.2] font-[500] text-[#FF5131] mt-[10px]">{t('offers.7')}</p>
                    <p className="manrope text-[16px] xl:text-[20px] leading-[1.4] font-[500] mt-[10px] opacity-[0.7]">{t('offers.8')}</p>
                    <div className="xl:w-[497px] p-[20px] xl:p-0 xl:h-[64px] rounded-[10px] bg-[#EAEAE5] flex flex-col justify-center mt-[20px] xl:mt-[40px]">
                        <p className="manrope text-[14px] xl:text-[16px] leading-[1.4] font-[500] xl:mx-[20px]">{t('offers.9')}</p>
                        <p className="roboto text-[14px] xleading-[1.4] xl:mx-[20px] text-[#000000B2]">{t('offers.10')}</p>
                    </div>
                    <a href="https://user.desc-account.icu/signup">
                        <div className="button_main mt-[60px]">
                            <p className="text-white roboto text-[16px] xl:text-[20px] uppercase font-[5600]">{t('offers.11')}</p>
                        </div>
                    </a>
                </div>
                <div className="mt-[20px] xl:mt-0">
                    <img src={two} alt="" />
                </div>
           </div>
        </div>
    );
}

export default TakeAdvantages;
