import React, { useEffect } from "react";

import Main from '../components/ProductsOverview/Main'
import GOtothenextlevel from "../components/ProductsOverview/GOtothenextlevel";
import Switcher from "../components/ProductsOverview/Switcher";
import Start from "../components/ProductsOverview/Start";


function ProductsOverview () {
 
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div className="bg-[#F4F4EF]">
            <Main/>
            <GOtothenextlevel/>
            <Switcher/>
            <Start/>
        </div>
    );
}

export default ProductsOverview;
