import React from "react";

import { useTranslation, Trans } from "react-i18next";

import l1 from '../../img/ContactUs/layer1.png'
import l2 from '../../img/ContactUs/layer2.png'
import l3 from '../../img/ContactUs/layer3.png'

function TradeMultiAward() {
    
    const { t } = useTranslation();

    return (
        <div className="bg-[#F4F4EF] overflow-hidden relative">
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto pt-[80px] xl:pt-[160px] relative z-50 flex flex-col items-center">
                <p className="manrope text-[20px] xl:text-[40px] font-[500] leading-[1.2] text-center"><Trans>{t('contactus.13')}</Trans></p>
                <div className="flex flex-col xl:flex-row gap-[50px] mt-[50px]">
                    <img src={l1} alt="" />
                    <img src={l2} alt="" />
                    <img src={l3} alt="" />
                </div>
            </div>
        </div>
    );
}

export default TradeMultiAward;

