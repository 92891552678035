import React from "react";

import { useTranslation, Trans } from "react-i18next";

import group from '../../img/Offers/group.png'

import Fade from 'react-reveal/Fade'

function WhatYouCan() {
    
    const { t } = useTranslation();

    return (
        <div className="overflow-hidden relative">
           <div className="max-w-[1280px] mx-[20px] xl:mx-auto mt-[80px] xl:mt-[160px] relative">
            <p className="text-[20px] xl:text-[40px] font-[500] leading-[1.2] manrope text-center">{t('offers.46')}</p>
            <p className="text-[32px] xl:text-[64px] font-[500] leading-[1.2] manrope text-center text-[#FF5131]">{t('offers.47')}</p>
            <div className="flex flex-col xl:flex-row justify-between mt-[30px] xl:mt-[60px]">
                <div className="xl:w-[497px] h-[221px] rounded-[10px] bg-[#FF51311A] p-[30px]">
                    <p className="text-[16px] xl:text-[24px] manrope font-[500] leading-[1.4] text-[#FF5131]">{t('offers.48')}</p>
                    <p className="manrope text-[14px] xl:text-[16px] font-[500] opacity-[0.7] mt-[20px]"><span className="text-[#FF5131] mr-[15px]">•</span>{t('offers.49')}</p>
                    <p className="manrope text-[14px] xl:text-[16px] font-[500] opacity-[0.7] mt-[8px]"><span className="text-[#FF5131] mr-[15px]">•</span>{t('offers.50')}</p>
                    <p className="manrope text-[14px] xl:text-[16px] font-[500] opacity-[0.7] mt-[8px]"><span className="text-[#FF5131] mr-[15px]">•</span>{t('offers.51')}</p>
                </div>
                <div className="xl:w-[497px] h-[221px] rounded-[10px] bg-[#FF51311A] p-[30px] mt-[20px] xl:mt-0">
                    <p className="text-[16px] xl:text-[24px] manrope font-[500] leading-[1.4] text-[#FF5131]">{t('offers.52')}</p>
                    <p className="manrope text-[14px] xl:text-[16px] font-[500] opacity-[0.7] mt-[20px]"><span className="text-[#FF5131] mr-[15px]">•</span>{t('offers.53')}</p>
                    <p className="manrope text-[14px] xl:text-[16px] font-[500] opacity-[0.7] mt-[8px]"><span className="text-[#FF5131] mr-[15px]">•</span>{t('offers.54')}</p>
                    <p className="manrope text-[14px] xl:text-[16px] font-[500] opacity-[0.7] mt-[8px]"><span className="text-[#FF5131] mr-[15px]">•</span>{t('offers.55')}</p>
                </div>
            </div>
           </div>
        </div>
    );
}

export default WhatYouCan;
