import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import button from '../../img/Accounts/plus.png'

const faqData = [
    {
        question: 'accounts.67',
        answer: 'accounts.68'
    },
    {
        question: 'accounts.69',
        answer: 'accounts.70'
    },
    {
        question: 'accounts.71',
        answer: 'accounts.72'
    },
    {
        question: 'accounts.73',
        answer: 'accounts.74'
    },
    {
        question: 'accounts.75',
        answer: 'accounts.76'
    },
]

function FAQ() {
    
    const { t } = useTranslation();

    const [openIndex, setOpenIndex] = useState(0);

    const toggleText = (index) => {
        setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    return (
        <div className="relative overflow-hidden mt-[80px] xl:mt-[160px]">
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col">
                <p className="text-[20px] xl:text-[40px] manrope leading-[1.2] font-[500] mx-auto">{t('accounts.65')}</p>
                <p className="text-[32px] xl:text-[64px] font-[500] leading-[1.2] manrope text-[#FF5131] mx-auto xl:pb-[30px] pb-0">{t('accounts.66')}</p>
                <div className="flex justify-between flex-col mt-[15px]">
                    {faqData.map((faq, index) => (
                        <div key={index}>
                            <div className={`rounded-[10px] flex justify-between mt-[20px] py-[10px] xl:h-[65px] transition-all duration-300 ${openIndex === index ? '!h-auto bg-[#FF51311A]' : 'bg-[#EAEAE5]'}`}>
                                <div className='mx-[25px]'>
                                    <p className='xl:mt-[12px] manrope text-[14px] xl:text-[16px] text-[#493843] max-w-[200px] xl:max-w-[1280px]'>{t(faq.question)}</p>
                                    {openIndex === index && (
                                        <p className='textcolor roboto text-[14px] xl:text-[16px] font-light mt-[12px] pb-[20px] max-w-[200px] xl:max-w-[802px]'>{t(faq.answer)}</p>
                                    )}
                                </div>
                                <div className='flex-shrink-0 w-[34px] h-[34px] rounded-[8px] flex items-center justify-center bg-[#FF5131] cursor-pointer mx-[25px] xl:mt-[5px]' onClick={() => toggleText(index)}>
                                    <img src={button} alt="" className={`${openIndex === index ? 'transition-transform rotate-45' : 'transition-transform'}`}/>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default FAQ;
