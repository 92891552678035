import React from "react";

import { useTranslation, Trans } from "react-i18next";

function QuicklyAbout() {
    
    const { t } = useTranslation();

    return (
        <div className="overflow-hidden relative max-w-[1280px] mx-[20px] xl:mx-auto mt-[80px] xl:mt-[160px]">
            <p className="manrope text-[28px] xl:text-[64px] leading-[1.2] xl:absolute"><Trans>{t('main.14')}</Trans></p>
            <div className="flex flex-col xl:flex-row  justify-end mt-[20px] xl:mt-0">
                <div className="xl:w-[393px] h-[116px] rounded-[10px] bg-[#FF5131] flex flex-col items-center justify-center">
                    <p className="roboto text-[16px] text-[#000000B3]">{t('main.15')}</p>
                    <p className="manrope text-[20px] xl:text-[36px] font-[500] text-[#000]">{t('main.16')}</p>
                </div>
            </div>
            <div className="flex flex-col xl:flex-row justify-end gap-[20px] xl:gap-[50px] mt-[20px] xl:mt-[50px]">
                <div className="xl:w-[393px] h-[116px] rounded-[10px] bg-[#EAEAE5] flex flex-col items-center justify-center">
                    <p className="roboto text-[16px] text-[#000000B3]">{t('main.17')}</p>
                    <p className="manrope text-[20px] xl:text-[36px] font-[500] text-[#000]">{t('main.18')}</p>
                </div>
                <div className="xl:w-[393px] h-[116px] rounded-[10px] bg-[#000] flex flex-col items-center justify-center">
                    <p className="roboto text-[16px] text-[#FFFFFFB3]">{t('main.19')}</p>
                    <p className="manrope text-[20px] xl:text-[36px] font-[500] text-[#fff]">{t('main.20')}</p>
                </div>
            </div>
            <div className="flex flex-col xl:flex-row  gap-[20px] mt-[20px] xl:mt-[50px] xl:gap-[50px]">
                <div className="xl:w-[393px] h-[116px] rounded-[10px] bg-[#EAEAE5] flex flex-col items-center justify-center">
                    <p className="roboto text-[16px] text-[#000000B3]">{t('main.21')}</p>
                    <p className="manrope text-[20px] xl:text-[36px] font-[500] text-[#000]">{t('main.22')}</p>
                </div>
                <div className="xl:w-[393px] h-[116px] rounded-[10px] bg-[#000] flex flex-col items-center justify-center">
                    <p className="roboto text-[16px] text-[#FFFFFFB3]">{t('main.23')}</p>
                    <p className="manrope text-[20px] xl:text-[36px] font-[500] text-[#fff]">{t('main.24')}</p>
                </div>
                <div className="xl:w-[393px] h-[116px] rounded-[10px] bg-[#EAEAE5] flex flex-col items-center justify-center">
                    <p className="roboto text-[16px] text-[#000000B3]">{t('main.25')}</p>
                    <p className="manrope text-[20px] xl:text-[36px] font-[500] text-[#000]">{t('main.26')}</p>
                </div>
            </div>
            <div className="flex flex-col xl:flex-row  gap-[20px] mt-[20px] xl:mt-[50px] xl:gap-[50px]">
                <div className="xl:w-[393px] h-[116px] rounded-[10px] bg-[#EAEAE5] flex flex-col items-center justify-center">
                    <p className="roboto text-[16px] text-[#000000B3]">{t('main.27')}</p>
                    <p className="manrope text-[20px] xl:text-[36px] font-[500] text-[#000]">{t('main.28')}</p>
                </div>
            </div>
        </div>
    );
}

export default QuicklyAbout;
