import React, { useState } from "react";

import { useTranslation, Trans } from "react-i18next";

import check from '../../img/Home/check.png'

import Fade from 'react-reveal/Fade'

const data = [ 
    {
        title: 'products.9',
        title2: 'products.10',
        texttitle: 'products.11',
        text1: 'products.12',
        text2: 'products.13',
        text3: 'products.14',
    },
    {
        title: 'products.15',
        title2: 'products.16',
        texttitle: 'products.17',
        text1: 'products.18',
        text2: 'products.19',
        text3: 'products.20',
    },
    {
        title: 'products.21',
        title2: 'products.22',
        texttitle: 'products.23',
        text1: 'products.24',
        text2: 'products.25',
        text3: 'products.26',
    },
    {
        title: 'products.27',
        title2: 'products.28',
        texttitle: 'products.29',
        text1: 'products.30',
        text2: 'products.31',
        text3: 'products.32',
    },
    {
        title: 'products.33',
        title2: 'products.34',
        texttitle: 'products.35',
        text1: 'products.36',
        text2: 'products.37',
        text3: 'products.38',
    },
    {
        title: 'products.39',
        title2: 'products.40',
        texttitle: 'products.41',
        text1: 'products.42',
        text2: 'products.43',
        text3: 'products.44',
    },
]

function Switcher() {
    
    const { t } = useTranslation();

    const [active, setActive] = useState(0);

    return (
        <div className="bg-[#F4F4EF] overflow-hidden relative mt-[80px] xl:mt-[160px]">
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col xl:flex-row justify-between">
                <div className="flex xl:flex-col gap-[20px] overflow-x-scroll xl:overflow-hidden pb-[10px]">
                    {data.map((fff, index) => (
                        <div className="flex items-center" key={index}>
                            <div className={`hidden xl:block w-[18px] h-[18px] rounded-full transition-all  ${active === index ? 'bg-[#FF5131]' : 'border border-[#00000033]'}`}></div>
                            <div className={`hidden xl:block w-[31px] h-[1px] transition-all ${active === index ? 'bg-[#FF5131]' : 'bg-[#00000033]'}`}></div>
                            <div className={`flex items-center justify-center cursor-pointer w-[187px] h-[58px] rounded-[10px] transition-all ${active === index ? 'bg-[#FF5131]' : 'border border-[#00000033]'}`} onClick={() => setActive(index)}>
                                <p className="manrope text-[16px] xl:text-[20px] leading-[1.2] font-[500]">{t(fff.title)}</p>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="mt-[20px] xl:mt-0">
                    <p className="text-[#FF5131] manrope font-[500] leading-[1.2] text-[32px] xl:text-[64px]">{t(data[active].title2)}</p>
                    <p className="text-[#000] opacity-[0.7] manrope font-[500] leading-[1.5] text-[14px] xl:text-[16px] max-w-[809px] mt-[20px]">{t(data[active].texttitle)}</p>
                    <div className="flex flex-col mt-[20px] xl:mt-[40px] gap-[25px]">
                        <div className="flex items-center gap-[20px]">
                            <img src={check} alt="" />
                            <p className="roboto text-[14px] xl:text-[16px] text-[#000000B2]">{t(data[active].text1)}</p>
                        </div>
                        <div className="flex items-center gap-[20px]">
                            <img src={check} alt="" />
                            <p className="roboto text-[14px] xl:text-[16px] text-[#000000B2]">{t(data[active].text2)}</p>
                        </div>
                        <div className="flex items-center gap-[20px]">
                            <img src={check} alt="" />
                            <p className="roboto text-[14px] xl:text-[16px] text-[#000000B2]">{t(data[active].text3)}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Switcher;
